import store from 'store'

import APIFunction from './../services';
// import LocalStorageService from "./../services/LocalStorageService";

// get user store
export const getUserStore = () => {
    return store.get('auth');
}

// get user info
export const queryUserInfo = (params) => {
    return APIFunction.queryUserInfo(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// update user info
export const updateUserInfo = (params) => {
    return APIFunction.updateUserInfo(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// get user info
export const changeUserPassword = (params) => {
    return APIFunction.changeUserPassword(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// get user store
export const removeUserStore = () => {
    return store.remove('auth');
}

// const localStorageService = LocalStorageService.getService();

// query Category
export const listCategory = (params) => {
    return APIFunction.queryCategory(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}


// create Category
export const createCategory = (params) => {
    return APIFunction.createCategory(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// update Category
export const updateCategory = (params) => {
    return APIFunction.updateCategory(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// delete Category
export const deleteCategory = (id) => {
    return APIFunction.deleteCategory({id: id}).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}
