import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import { withRouter } from 'react-router-dom';

import {
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@material-ui/core";

import LocalStorageService from "./../services/LocalStorageService";
import { AccountCircleOutlined } from "@material-ui/icons";
// import ChangePassword from "../pages/auth/ChangePassword";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 35px;
    height: 35px;
  }
`;

const localStorageService = LocalStorageService.getService();

class UserMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      anchorMenu: null
    };
  }

  toggleMenu = event => {
    this.setState({ anchorMenu: event.currentTarget });
  };

  closeMenu = (action) => {
    this.setState({ anchorMenu: null });
    if (action === 'signOut') {
      localStorageService.clearToken()
      this.props.history.push('/auth/sign-in')
    }

  };

  render() {
    const { anchorMenu } = this.state;
    const open = Boolean(anchorMenu);

    return (
      <React.Fragment>
        <IconButton
          aria-owns={open ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={this.toggleMenu}
          color="inherit"
        >
          <AccountCircleOutlined/>
        </IconButton>
        <Menu
          // id="menu-appbar"
          id="customized-menu"
          anchorEl={anchorMenu}
          open={open}
          onClose={() => this.closeMenu()}
        >
          {/* <MenuItem
            onClick={() => {
              this.closeMenu('');
            }}
          >
            Profile
          </MenuItem> */}
          {/* <MenuItem
            onClick={() => {
              this.closeMenu('');
            }}
          >
            Change Password
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              this.closeMenu('signOut');
            }}
          >
            Sign out
          </MenuItem>
        </Menu>
        {/* <ChangePassword ></ChangePassword> */}
      </React.Fragment>
    );
  }
}

export default withRouter(withTheme(UserMenu));