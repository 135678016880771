import React from "react";
import { withStyles } from '@material-ui/core/styles';
import {
    Typography,
    Drawer,
    ListItem,
    Fab as MuiFab,
    Grid,
    FormControl,
    Button as MuiButton,
    Snackbar,
    InputAdornment,
    IconButton,
    CircularProgress,
    Button,
    TextField,
    Select,
    MenuItem
} from "@material-ui/core";
import { green } from '@material-ui/core/colors';
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
    KeyboardDatePicker
} from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from "prop-types";
import { createCategory, updateCategory } from "./../../models/index"
const Fab = styled(MuiFab)`
  position: fixed;
  right: ${props => props.theme.spacing(8)}px;
  bottom: ${props => props.theme.spacing(8)}px;
`;

const Wrapper = styled.div`
  width: 60vw;
  overflow-x: hidden;
`;
const Heading = styled(ListItem)`
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.palette.common.black};
  background: ${props => props.theme.palette.common.white};
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 56px;

  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

const styles = {
    root: {
        borderRadius: '0px'
    },
};

export class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorMenu: null,
            isOpen: false,
            errorMessage: '',
            classes: '',
            isSubmited: false,
            error: false,
            errorServer: false,
            success: false,
            id: "",
            name: "",
            name_vi: "",
            description: "",
            status: 2,
            created_at: "",
            created_by: "",
            updated_at: "",
            updated_by: "",
            data: null
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data !== prevState.data) {
            return {
                data: nextProps.data,
                id: nextProps.data.id,
                name: nextProps.data.name,
                name_vi: nextProps.data.name_vi,
                description: nextProps.data.description,
                status: nextProps.data.status,
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            //Perform some operation here
            this.setState({
                data: prevProps.data,
                id: prevProps.data.id,
                name: prevProps.data.name,
                name_vi: prevProps.data.name_vi,
                description: prevProps.data.description,
                status: prevProps.data.status,
            });
        }
    }

    onSave = () => {
        const {
            id,
            name,
            name_vi,
            description,
            status,
            data
        } = this.state;
        const that = this;
        this.setState({isSubmited: true});
        if (data) {
            updateCategory({ id: id, name: name, name_vi: name_vi, description: description, status: status }).then((res) => {
                if (res.success) {
                    this.props.onRefeshData()
                    this.props.onClose()
                } else {

                }
                this.setState({isSubmited: false});
            })

        } else {
            createCategory({ name: name, name_vi: name_vi, description: description, status: status }).then((res) => {
                if (res.success) {
                    this.props.onRefeshData()
                    this.props.onClose()
                } else {

                }
                
                this.setState({isSubmited: false});
            })
        }
    }

    render() {
        const {
            isSubmited,
            error,
            errorServer,
            success,
            name,
            name_vi,
            description,
            status
        } = this.state;


        return (
            <Wrapper>
                <Heading>Category</Heading>
                <Grid container style={{ paddingLeft: 16, paddingRight: 16 }}>
                    <Grid item md={12} xs={12}>
                        <ValidatorForm
                            ref="form"
                            onSubmit={this.onSave}
                            onError={() => { this.setState({ isOpen: false, errorMessage: '' }) }}
                        >
                            <Grid container >
                                <Grid item md={6} xs={12} style={{ paddingRight: 16 }} >
                                    <FormControl margin="normal" required fullWidth>
                                        <Typography>Full name *</Typography>
                                        <TextValidator
                                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                            name="name"
                                            value={name}
                                            validators={['required']}
                                            errorMessages={['Required']}
                                            inputProps={{
                                                maxLength: 255,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} xs={12} style={{ paddingRight: 16 }}>
                                    <FormControl margin="normal" required fullWidth>
                                        <Typography>Name VI *</Typography>
                                        <TextValidator
                                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                            name="name_vi"
                                            value={name_vi}
                                            validators={['required']}
                                            errorMessages={['Required']}
                                            inputProps={{
                                                maxLength: 255,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid item md={6} xs={12} style={{ paddingRight: 16 }} >
                                    <FormControl margin="normal" required fullWidth>
                                        <Typography>Description</Typography>
                                        <TextValidator
                                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                            name="description"
                                            value={description}
                                            // validators={['required']}
                                            // errorMessages={['Required']}
                                            inputProps={{
                                                maxLength: 255,
                                            }}
                                        />
                                    </FormControl>

                                </Grid>
                                <Grid item md={6} xs={12} style={{ paddingRight: 16 }}>
                                    <Typography style={{ paddingTop: 16 }}>Status</Typography>
                                    <Select
                                        style={{ marginTop: 4 }}
                                        name="status"
                                        fullWidth
                                        margin="dense"
                                        value={status}
                                        onChange={e => this.setState({ [e.target.name]: e.target.value })}
                                    >
                                        <MenuItem
                                            key={'1'}
                                            value={1}
                                        >
                                            Not Active
                                        </MenuItem>
                                        <MenuItem
                                            key={'2'}
                                            value={2}
                                        >
                                            Active
                                    </MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>

                            <Grid container style={{ textAlign: "right" }} >
                                <Grid item md={6} xs={12} >
                                </Grid>
                                <Grid item md={6} xs={12} style={{ paddingRight: 16 }} >
                                    <Button
                                        disabled={isSubmited}
                                        variant="contained"
                                        color="primary"
                                        style={{ marginTop: 20, marginRight: 10 }}
                                        mt={2}
                                        type="submit">
                                        {isSubmited && <CircularProgress style={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            width: 20,
                                            height: 20,
                                            marginTop: -10,
                                            marginLeft: -5,
                                        }} />}
                                        Save
                                    </Button>

                                    <Button
                                        disabled={isSubmited}
                                        variant="contained"
                                        color="default"
                                        style={{ marginTop: 20 }}
                                        mt={2}
                                        onClick={this.props.onClose}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </Grid>
                </Grid>
            </Wrapper>
        );
    }
}

Category.propTypes = {
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(Category);