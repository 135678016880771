export default {
  queryRouteList: '/routes',

  queryUserInfo: 'GET /user/:id',
  checkUser: 'GET /auth/user/:id',
  queryListUser: 'GET /user',
  updateUserInfo: 'PUT /user/:id',
  changeUserPassword: 'POST /change-password',
  logout: 'POST /auth/logout',
  login: 'POST /auth/login',
  forgetPassword: 'POST /forgot-password',
  createUser: 'POST /user',
  checkTokenVaid: '/forgot-password',
  registerNewPassword: 'PUT /forgot-password/:id',
  activeUser: 'POST /active-user',
  queryCategory: 'GET /category',
  createCategory: 'POST /category',
  updateCategory: 'PUT /category/:id',
  deleteCategory: 'DELETE /category/:id',
  queryPost: 'GET /post',
  queryPostInfor: 'GET /post/:id',

}
