import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import { CategoryTableRow } from './CategoryTableRow';
import { listCategory } from '../../models/index';
import {
  Grid,
  Card as MuiCard,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress as Loading,
  TablePagination,
  TableSortLabel,
  Tooltip,
  TextField,
  Select,
  MenuItem,
  Button,
  Drawer
} from "@material-ui/core";
import moment from 'moment';

import { spacing } from "@material-ui/system";
import {
  KeyboardDatePicker
} from '@material-ui/pickers';
import { Plus } from "react-feather";
import EditCategory from "./EditCategory";
import { deleteCategory } from "./../../models/index";

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const styles = {
  root: {
    borderRadius: '0px'
  },
};

const TableCellHeader = styled(TableCell)`
padding-top: 0px !important;
padding-bottom: 2px !important;
padding-left: 2px !important;
padding-right: 2px !important;
`;


const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(82vw - ${props => props.theme.spacing(6)}px);
`;

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rowUser = [
  { id: "no", label: "No." },
  { id: "action", label: "Action" },
  { id: "name", label: "Name", sort: true },
  { id: "name_vi", label: "Name VI", sort: true },
  { id: "description", label: "Description", sort: true },
  { id: "status", label: "Status", sort: true },
  { id: "created_at", label: "Created At", sort: true },
  { id: "created_by", label: "Created By", sort: true },
  { id: "updated_at", label: "Updated At", sort: true },
  { id: "updated_by", label: "Updated By", sort: true }
];

class EnhancedTableHead extends React.Component {

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      order,
      orderBy
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rowUser.map(
            row => (
              <TableCell
                key={row.id}
                align="center"
                sortDirection={orderBy === row.id ? order : false}
              >
                {row.sort ?
                  <Tooltip
                    title="Sort"
                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={orderBy === row.id ? order : 'asc'}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                  : row.label
                }
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};


class EnhancedTableHeadFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      name_vi: "",
      status: "",
      created_at: "",
      created_by: "",
      updated_at: "",
      updated_by: ""
    }
  }

  render() {
    return (
      <TableHead>
        <TableRow>
          {rowUser.map(
            row => (
              <TableCellHeader
                key={row.id}
                align="left"
              >
                {
                  row.id != 'no' && row.id != 'action' && row.id != 'avatar' ?
                    row.id == 'status' ?
                      <Select
                        style={{ marginTop: 4 }}
                        name={row.id}
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        value={this.state[row.id] ? this.state[row.id] : 0}
                        onChange={async (e) => {
                          await this.setState({ [row.id]: e.target.value });
                          this.props.onRequestFilter(this.state)
                        }}
                      >
                        <MenuItem value={0}>All</MenuItem>
                        <MenuItem
                          key={'1'}
                          value={1}
                        >
                          Not activated
                        </MenuItem>
                        <MenuItem
                          key={'2'}
                          value={2}
                        >
                          Activated
                        </MenuItem>
                      </Select> :
                      row.id == 'created_at' || row.id == 'updated_at' ?
                        <KeyboardDatePicker
                          fullWidth
                          variant="outlined"
                          id={row.id}
                          format="dd/MM/yyyy"
                          value={this.state[row.id] ? this.state[row.id] : null}
                          onChange={async (date) => {
                            let dateFm = null;
                            if (date) {
                              dateFm = moment(date).format("YYYY-MM-DD");
                              if (moment(date).isValid()) {
                                await this.setState({ [row.id]: dateFm });
                              } else {
                                await this.setState({ [row.id]: null });
                              }
                            } else {
                              await this.setState({ [row.id]: null });
                            }
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          placeholder="dd/mm/yyyy"
                          style={{ paddingRight: "10px", marginTop: "10px" }}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              this.props.onRequestFilter(this.state)
                            }
                          }}
                        /> :
                        <TextField
                          fullWidth
                          name={row.id}
                          id="outlined-margin-dense"
                          margin="dense"
                          variant="outlined"
                          value={this.state[row.id]}
                          onChange={(e) => {
                            this.setState({ [row.id]: e.target.value })
                          }}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              this.props.onRequestFilter(this.state)
                            }
                          }}
                        /> : null
                }

              </TableCellHeader>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHeadFilter.propTypes = {
  onRequestFilter: PropTypes.func.isRequired,
};

export class CategoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      order: "",
      orderBy: "",
      data: [],
      loading: false,
      openDialog: false,
      openDialogError: false,
      error_message: null,
      saving: false,
      total_items: 0,
      page_size: 10,
      page: 0,
      name: "",
      name_vi: "",
      description: "",
      status: "",
      created_at: "",
      created_by: "",
      updated_at: "",
      updated_by: "",
      dataEdit: null
    };
  }

  async componentDidMount() {
    this.getDatas();
  }

  onRefeshData = () => {
    this.setState({ loading: true });
    this.getDatas();

  }

  getDatas = () => {
    const params = {
      page: this.state.page + 1,
      page_size: this.state.page_size,
      name: this.state.name,
      name_vi: this.state.name_vi,
      description: this.state.description,
      status: this.state.status,
      created_at: this.state.created_at,
      created_by: this.state.created_by,
      updated_at: this.state.updated_at,
      updated_by: this.state.updated_by,
      sort_type: this.state.order,
      sort_name: this.state.orderBy
    };

    this.setState({ loading: true });

    listCategory(params).then((result) => {
      if (result.success) {
        let data = result._embedded && result._embedded.category ? result._embedded.category : [];
        this.setState({
          data: data,
          page: result.page > 0 ? result.page - 1 : result.page,
          page_size: result.page_size,
          total_items: result.total_items,
          loading: false,
        });
      }
    }).catch(function (error) {
      // that.setState({ isOpen: true, errorMessage: error.message })
    });

  }

  handleRequestSort = async (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    await this.setState({ order, orderBy });
    this.getDatas();
  };

  handleChangePage = async (event, newPage) => {
    await this.setState({ page: newPage });
    this.getDatas();
  };

  handleChangeRowsPerPage = async (event) => {
    await this.setState({
      page_size: event.target.value,
      page: 0
    });
    this.getDatas();
  };

  onRequestFilter = async (data) => {
    await this.setState({
      name: data.name,
      name_vi: data.name_vi,
      description: data.description,
      status: data.status,
      created_at: data.created_at,
      created_by: data.created_by,
      updated_at: data.updated_at,
      updated_by: data.updated_by
    })

    this.getDatas();

  };

  toggleDrawer = (open) => {
    // this.closeMenu('');
    this.setState({ isOpen: open });
  };

  onEdit = (data) => {
    this.setState({ isOpen: true, dataEdit: data });
  }

  onDelete = (id) => {
    this.setState({ loading: true });
    deleteCategory(id).then((res) => {
      if (res.success) {
        this.onRefeshData();
      } else {

      }
    })
  }

  render() {
    const {
      data,
      order,
      orderBy,
      loading,
      total_items,
      page_size,
      page
    } = this.state;

    return (
      <Grid container >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Card style={{ padding: '10px' }} >
                <Paper spacing={6} >
                  <Grid container>
                    <Grid item xs={12} style={{ textAlign: "right" }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={async () => {
                          this.toggleDrawer(true);
                          await this.setState({ dataEdit: null });
                        }}>
                        <Plus></Plus>New Category
                        </Button>
                      <Drawer anchor="right" open={this.state.isOpen} onClose={() => this.toggleDrawer(false)}>
                        <EditCategory
                          anchor="right"
                          open={this.state.isOpen}
                          onClose={() => this.toggleDrawer(false)}
                          onRefeshData={this.onRefeshData}
                          data={this.state.dataEdit}
                        />
                      </Drawer>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TableWrapper className="tableWrapper">
                        <Table aria-labelledby="tableTitle">
                          <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                          />
                          <EnhancedTableHeadFilter onRequestFilter={this.onRequestFilter} />
                          <TableBody>
                            {!loading ? (data && data.length > 0 ? (
                              // stableSort(data, getSorting(order, orderBy)).map((n, index) => {
                              data.map((n, index) => {
                                return (
                                  <CategoryTableRow
                                    key={n.id}
                                    rowData={n} index={index}
                                    onChangeValueCell={this.onChangeValueCell}
                                    onRefeshData={this.onRefeshData}
                                    data={data}
                                    onEdit={this.onEdit}
                                    onDelete={this.onDelete}
                                  >
                                  </CategoryTableRow>
                                );
                              })) :
                              <TableRow style={{ height: 230 }} >
                                <TableCell colSpan={14} style={{ textAlign: 'center' }}>
                                  No Data
                                </TableCell>
                              </TableRow>) :
                              <TableRow style={{ height: 230 }} >
                                <TableCell colSpan={14} style={{ textAlign: 'center' }}>
                                  <Loading />
                                </TableCell>
                              </TableRow>
                            }
                          </TableBody>
                        </Table>
                      </TableWrapper>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        component="div"
                        count={total_items}
                        rowsPerPage={page_size}
                        page={page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

CategoryList.propTypes = {
};

export default withStyles(styles, { withTheme: true })(CategoryList);  