import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  TableCell,
  TableRow,
  ListItemText as MuiListItemText,
  CircularProgress as Loading,
  Chip,
  Avatar,
  Button as MuiButton
} from "@material-ui/core";
import moment from 'moment';

import {
  Trash2, Edit
} from "react-feather";

const styles = theme => ({
  root: {
    borderRadius: '0px'
  },
});

const Button = styled(MuiButton)`
  min-width: 20px;
`;

export class CategoryTableRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      id: null,
      saving: false,
      disabling: false,
      status: '',
    }
  }

  componentDidMount() {
    let { rowData } = this.props;
  }


  render() {
    const {
      rowData,
      index
    } = this.props;

    const {
      saving,
      disabling,
    } = this.state;

    return (
      <TableRow
        hover
        tabIndex={-1}
        key={rowData.id}
      >
        <TableCell align="center" component="th" scope="row" padding="default">{index + 1}</TableCell>
        <TableCell align="center" component="th" scope="row" padding="default" style={{ minWidth: 100 }}>
          <Button onClick={() => { this.props.onEdit(rowData); }} color="primary" >
            <Edit size={14}></Edit>
          </Button>
          <Button onClick={() => {  this.props.onDelete(rowData.id); }} style={{ color: "#dc004e" }} >
            <Trash2 size={14}> </Trash2>
          </Button>
        </TableCell>
        <TableCell align="left" style={{ minWidth: 200 }}>
          {rowData.name}
        </TableCell>
        <TableCell align="left" style={{ minWidth: 200 }}>
          {rowData.name_vi}
        </TableCell>
        <TableCell align="left" style={{ minWidth: 200 }}>
          {rowData.description}
        </TableCell>
        
        <TableCell align="center" style={{ minWidth: 100 }}>
          {
            rowData.status == 1 ?
              <Chip label="Not activated"></Chip> : <Chip label="Activated" style={{ backgroundColor: "#4caf50", color: "#fff" }}> </Chip>
          }
        </TableCell>
        <TableCell align="center" style={{ minWidth: 165 }}>
          {rowData.created_at}
        </TableCell>
        <TableCell align="center" style={{ minWidth: 150 }}>
          {rowData.created_by}
        </TableCell>
        <TableCell align="center" style={{ minWidth: 165 }}>
          {rowData.updated_at}
        </TableCell>
        <TableCell align="center" style={{ minWidth: 150 }}>
          {rowData.updated_by}
        </TableCell>
      </TableRow>

    );
  }


  onCloseBtnAction = () => {
    this.setState({ anchorEl: null });
  };

  onOpenBtnAction = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  onChangeText = (name, i, value) => {
    this.setState({ [name]: value });
    if (name == 'phone') {
      this.setState({ error_phone: false, error_phone_empty: false });
      if (this.props.data && this.props.data.length > 0) {
        this.props.data.forEach((element, index) => {
          if (element.phone == value && i != index) {
            this.setState({ error_phone: true });
          }
        });
      }

      if (value === '') {
        this.setState({ error_phone_empty: true });
      }
    }

    if (name == 'email') {
      this.setState({ error_email: false, error_email_empty: false });
      if (this.props.data && this.props.data.length > 0) {
        this.props.data.forEach((element, index) => {
          if (element.email == value && i != index) {
            this.setState({ error_email: true });
          }
        });
      }
      if (value === '') {
        this.setState({ error_email_empty: true });
      }
    }
    // this.props.onChangeValueCell(name, value, index)
  }

  onChangeDate = (date, name, index) => {
    this.setState({ [name]: date });

    // this.props.onChangeValueCell(name, date, index)
  }

  changeStatus = (id, value, index) => {
    this.setState({ disabling: true })
    let data = {
      id: id,
      status: value
    }

  }
}

CategoryTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(CategoryTableRow);