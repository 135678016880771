import React from "react";

import async from "../components/Async";

import {
  List,
  Sliders,
  Users,
  ShoppingCart,
  Grid,
} from "react-feather";
import Category from "../pages/category/Category";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
// const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));
// const Analytics = async(() => import("../pages/dashboards/Analytics"));

const User = async(() => import("../pages/user/User"));

const PostComment = async(() => import("../pages/post-comment/Index"));

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/",
  // header: "Main",
  icon: <Sliders />,
  component: Default,
  children: null
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
    // {
    //   path: "/auth/sign-up",
    //   name: "Sign Up",
    //   component: SignUp
    // },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ]
};

const categoryRoute = {
  id: "Category",
  path: "/category",
  icon: <Grid />,
  children: null,
  component: Category,
  // permission: "1"
};

const postCommentRoute = {
  id: "Post/Comment",
  path: "/post-comment",
  icon: <List />,
  children: null,
  component: PostComment,
  // permission: "1"
};


const userRoute = {
  id: "User",
  path: "/user",
  icon: <Users />,
  children: null,
  component: User,
  // permission: "1"
};

export const auth = [authRoutes];

export const dashboard = [
  dashboardsRoutes,
  categoryRoute,
  postCommentRoute,
  userRoute
];

export default [
   dashboardsRoutes,
   categoryRoute,
   postCommentRoute,
   userRoute
];
